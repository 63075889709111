import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CaseUiData } from "../../schema/CaseUiSchema";

const PatientResearchId = (): React.JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <FormInput
      id="patientResearchId"
      label="Research patient ID"
      className="is-uppercase"
      error={formState.errors.patientResearchId}
      {...register("patientResearchId", {
        setValueAs: (value: string) => value.toUpperCase(),
      })}
    />
  );
};

export default PatientResearchId;
