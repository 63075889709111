import classNames from "classnames";
import React from "react";

interface FieldsetProps {
  className?: string;
  title: string;
  disabled: boolean;
  children: React.ReactNode;
  addon?: React.ReactNode;
}

const Fieldset = ({
  className,
  title,
  disabled,
  children,
  addon,
}: FieldsetProps): React.JSX.Element => {
  return (
    <fieldset className={classNames("box p-5", className)} disabled={disabled}>
      {title && (
        <h3 className="title is-4">
          {title}
          {addon}
        </h3>
      )}
      {children}
    </fieldset>
  );
};

export default Fieldset;
