import React from "react";

import Fieldset from "../../components/forms/Fieldset";
import { FormSelectOption } from "../../components/forms/FormSelect";
import { FormMode } from "../FormWrapper";
import { Macro, Pathologist, ReceiptDate, Slides } from "../fields/lab";

interface LabUseOnlyProps {
  forceP53?: boolean;
  formMode: FormMode;
  disabled: boolean;
  consultants: FormSelectOption[];
}

const LabUseOnly = ({
  forceP53,
  formMode,
  disabled,
  consultants,
}: LabUseOnlyProps): React.JSX.Element => {
  return (
    <Fieldset title="Lab use only" disabled={disabled}>
      <div className="columns">
        <div className="column is-4-desktop">
          <Slides forceP53={forceP53} />
          <ReceiptDate formMode={formMode} />
          <Pathologist
            formMode={formMode}
            formLocked={disabled}
            consultants={consultants}
          />
        </div>
        <div className="column is-8-desktop">
          <Macro formLocked={disabled} />
        </div>
      </div>
    </Fieldset>
  );
};

export default LabUseOnly;
