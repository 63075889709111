import React from "react";
import { Link } from "react-router";

export const TEST_ID_NO_RESULTS_SUGGESTIONS = "NoResultsSuggestions";
export const TEST_ID_NO_RESULTS_NEW_CASE_LINK = "NoResultsNewCaseLink";

const NoResults = (): React.JSX.Element => {
  return (
    <div className="content" data-testid={TEST_ID_NO_RESULTS_SUGGESTIONS}>
      <p>Suggestions:</p>
      <ul>
        <li>Check your search terms are spelled correctly</li>
        <li>Broaden your search by using fewer filters</li>
        <li>
          <Link to="/" data-testid={TEST_ID_NO_RESULTS_NEW_CASE_LINK}>
            Create a new case
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NoResults;
