import { faBellSlash } from "@fortawesome/free-regular-svg-icons";
import { faCog, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { intersection, map } from "lodash";
import React, { MouseEvent } from "react";

import { PortalUserDialogType } from "../../routes/UserList";
import { PortalUser } from "../../schemas/PortalUserSchema";
import { UserAccountOperationType } from "../../schemas/UserSchema";
import LabelledIcon from "../atoms/LabelledIcon";
import MissingFieldTag from "../atoms/MissingFieldTag";
import UserMfaStatusIndicator from "./UserMfaStatusIndicator";
import UserStatusIndicator from "./UserStatusIndicator";

export const TEST_ID_EDIT_USER_BUTTON = "EditUserButton";
export const TEST_ID_MANAGE_USER_BUTTON = "ManageUserButton";

interface PortalUserRowProps {
  user: PortalUser;
  openUserDialog: (e: MouseEvent, d: PortalUserDialogType, id?: string) => void;
}

const PortalUserRow = ({
  user: {
    userId,
    firstName,
    lastName,
    email,
    status,
    mfaStatus,
    lastActive,
    groups,
    operations,
  },
  openUserDialog,
}: PortalUserRowProps): React.JSX.Element => {
  const { FORM, MANAGE } = PortalUserDialogType;
  const { ACTIVATE, DEACTIVATE, DISABLE_MFA, DELETE, UPDATE } = UserAccountOperationType;

  const userLastActiveDate: string = lastActive
    ? format(lastActive, "d MMM yyyy, h.mmaaa") // eg. 19 Jun 2024, 1.52pm
    : "Never";

  const userOperations = map(operations, "operation");
  const canEditUser = userOperations.includes(UPDATE);
  const canManageUser = !!intersection(userOperations, [
    ACTIVATE,
    DEACTIVATE,
    DISABLE_MFA,
    DELETE,
  ]).length;

  return (
    <tr>
      <td>
        <div className="cy-user-row__name">
          {firstName ?? <MissingFieldTag message="No first name" />}{" "}
          {lastName ?? <MissingFieldTag message="No last name" />}
        </div>
        <div className="cy-user-row__email">{email}</div>
      </td>
      <td>
        {groups.map(({ emailNotifications, groupName, groupId }) => (
          <div className="cy-user-row__group" key={`${userId}-${groupId}`}>
            {!emailNotifications && (
              <FontAwesomeIcon
                icon={faBellSlash}
                title="Email notifications disabled"
                className="mr-1"
                size="sm"
              />
            )}
            {groupName || <MissingFieldTag message="No group name" />}
          </div>
        ))}
      </td>
      <td>{userLastActiveDate}</td>
      <td className="cy-user-row__status">
        <UserStatusIndicator status={status} />
        <UserMfaStatusIndicator mfaStatus={mfaStatus} />
      </td>
      <td>
        {canEditUser && (
          <a
            href=""
            role="button"
            className="is-block mb-1"
            data-testid={TEST_ID_EDIT_USER_BUTTON}
            onClick={(e) => openUserDialog(e, FORM, userId)}
          >
            <LabelledIcon icon={faPen} label="Edit" />
          </a>
        )}
        {canManageUser && (
          <a
            href=""
            role="button"
            className="is-block"
            data-testid={TEST_ID_MANAGE_USER_BUTTON}
            onClick={(e) => openUserDialog(e, MANAGE, userId)}
          >
            <LabelledIcon icon={faCog} label="Manage" />
          </a>
        )}
      </td>
    </tr>
  );
};

export default PortalUserRow;
