import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CaseUiData } from "../../schema/CaseUiSchema";

const PatientIdentifierAlternative = (): React.JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();
  return (
    <FormInput
      id="patientIdentifierAlternative"
      label="Other medical record no."
      help="e.g. Hospital number"
      error={formState.errors.patientIdentifierAlternative}
      {...register("patientIdentifierAlternative")}
    />
  );
};

export default PatientIdentifierAlternative;
