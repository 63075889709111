import classNames from "classnames";
import React, { ChangeEvent } from "react";

interface RadioOptionWithDescriptionProps {
  id: string;
  name: string;
  value: string;
  label: string;
  description?: string;
  selectedValue?: string;
  noMargin?: boolean;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RadioOptionWithDescription = ({
  id,
  name,
  value,
  label,
  description,
  selectedValue,
  noMargin = false,
  disabled = false,
  onChange,
}: RadioOptionWithDescriptionProps): React.JSX.Element => {
  return (
    <div
      className={classNames("columns is-mobile is-variable is-2", { "mb-0": noMargin })}
    >
      <div className="column is-narrow">
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={value === selectedValue}
          disabled={disabled}
          onChange={onChange}
        />
      </div>
      <label htmlFor={id} className="column is-clickable">
        <b className="is-block mb-1">{label}</b>
        {description}
      </label>
    </div>
  );
};

export default RadioOptionWithDescription;
