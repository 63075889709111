import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { setValueConfig } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { Answer } from "../../schema/FormAnswers";
import PatientIdentifierNotProvided from "./PatientIdentifierNotProvided";

interface PatientIdentifierProps {
  allowPatientIdentifierNotProvided?: boolean;
}

const PatientIdentifier = ({
  allowPatientIdentifierNotProvided,
}: PatientIdentifierProps): React.JSX.Element => {
  const { register, watch, formState, setValue, trigger } = useFormContext<CaseUiData>();
  const { patientIdentifierNotProvided } = watch();

  const updatePatientIdentifierFields = (checked: boolean): void => {
    if (checked) {
      // Set 'NHS/CHI number' field to 'Not provided' and focus adjacent field
      setValue("patientIdentifier", Answer.UNANSWERED, setValueConfig);
      trigger("patientIdentifierAlternative", { shouldFocus: true });
    } else {
      // Reset and focus 'NHS/CHI number' field; clear 'Required' on adjacent field
      setValue("patientIdentifier", "", setValueConfig);
      trigger("patientIdentifierAlternative");
      trigger("patientIdentifier", { shouldFocus: true });
    }
  };

  return (
    <>
      <FormInput
        id="patientIdentifier"
        label="NHS / CHI number"
        readOnly={patientIdentifierNotProvided}
        error={formState.errors.patientIdentifier}
        {...register("patientIdentifier")}
      />
      {allowPatientIdentifierNotProvided ? (
        <PatientIdentifierNotProvided
          onChange={(e) => {
            updatePatientIdentifierFields(e.target.checked);
          }}
        />
      ) : (
        <input
          type="hidden"
          id="patientIdentifierNotProvided"
          {...register("patientIdentifierNotProvided", { value: false })}
        />
      )}
    </>
  );
};

export default PatientIdentifier;
