import React from "react";
import { useFormContext } from "react-hook-form";

import FormDateInput from "../../../components/forms/FormDateInput";
import { CaseUiData } from "../../schema/CaseUiSchema";

const DateLastEndoscopy = (): React.JSX.Element => {
  const { control, formState } = useFormContext<CaseUiData>();

  return (
    <FormDateInput
      id="dateLastEndoscopy"
      label="Last diagnostic endoscopy"
      mode="month"
      control={control}
      help="Select January if only the year was provided"
      error={formState.errors.dateLastEndoscopy}
    />
  );
};

export default DateLastEndoscopy;
