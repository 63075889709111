import React from "react";

interface DialogErrorProps {
  children: React.ReactNode;
  testId?: string;
}

const DialogError = ({
  children,
  testId,
}: DialogErrorProps): React.JSX.Element | null => {
  if (!children) return null;
  return (
    <p className="notification is-danger" data-testid={testId}>
      {children}
    </p>
  );
};

export default DialogError;
