import React, { useEffect } from "react";

import PageHeader from "../components/pages/PageHeader";
import { SITE_TITLE } from "../helpers/strings";

const NotFound = (): React.JSX.Element => {
  // Page title
  const pageTitle = "404";
  useEffect(() => {
    document.title = `${pageTitle} | ${SITE_TITLE}`;
  }, []);

  return (
    <div className="container">
      <PageHeader title={pageTitle} subtitle="Page not found" />
      <div className="columns content">
        <div className="column is-two-thirds is-half-widescreen">
          <p>
            The page you’re looking for isn’t here. The URL may be incorrect or no longer
            available.
          </p>
          <p>
            Please check the browser address bar to ensure that there are no incorrect or
            missing characters in the URL, or navigate back and try a different link.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
