import { isNumber } from "lodash";
import React from "react";
import { useFormContext } from "react-hook-form";

import WarningMessage from "../../../components/atoms/WarningMessage";
import FormInput from "../../../components/forms/FormInput";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { PRAGUE_CLASSIFICATION_DIVISOR } from "../../schema/ValidationRules";

export const TEST_ID_FORM_PRAGUE_CLASSIFICATION_WARNING =
  "FormPragueClassificationWarning";

interface PragueClassificationProps {
  disabled: boolean;
}

const PragueClassification = ({
  disabled,
}: PragueClassificationProps): React.JSX.Element => {
  const { register, formState, watch } = useFormContext<CaseUiData>();
  const { circumferentialLength, maximalLength } = watch();

  // Show Prague classification warning whenever C is greater than M (unless
  // the case is locked)
  const pragueClassificationWarning: string | false =
    !disabled &&
    isNumber(circumferentialLength) &&
    isNumber(maximalLength) &&
    circumferentialLength > maximalLength &&
    "C should not be greater than M. Please check with the healthcare professional who submitted the form.";

  return (
    <>
      <h4 className="label has-text-weight-bold is-size-6 mb-1">Prague classification</h4>
      <div className="columns is-mobile mb-0">
        <div className="column is-half">
          <FormInput
            type="number"
            step={PRAGUE_CLASSIFICATION_DIVISOR}
            id="circumferentialLength"
            label="C (cm)"
            disabled={disabled}
            help="Leave empty if not provided"
            error={formState.errors.circumferentialLength}
            {...register("circumferentialLength", {
              valueAsNumber: true,
            })}
          />
        </div>
        <div className="column is-half">
          <FormInput
            type="number"
            step={PRAGUE_CLASSIFICATION_DIVISOR}
            id="maximalLength"
            label="M (cm)"
            disabled={disabled}
            help="Leave empty if not provided"
            error={formState.errors.maximalLength}
            {...register("maximalLength", { valueAsNumber: true })}
          />
        </div>
      </div>
      {!!pragueClassificationWarning && (
        <WarningMessage testId={TEST_ID_FORM_PRAGUE_CLASSIFICATION_WARNING}>
          {pragueClassificationWarning}
        </WarningMessage>
      )}
    </>
  );
};

export default PragueClassification;
