import React, { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import FormCheckbox from "../../../components/forms/FormCheckbox";
import { FormSelectOption } from "../../../components/forms/FormSelect";
import { CHECK_LIMS } from "../../../helpers/strings";
import { setValueConfig } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface UseClinicianForProcedureSiteProps {
  allowUseClinicianForProcedureSite?: boolean;
  clinicians: FormSelectOption[];
}

const UseClinicianForProcedureSite = ({
  allowUseClinicianForProcedureSite,
  clinicians,
}: UseClinicianForProcedureSiteProps): React.JSX.Element => {
  const { register, setValue, watch } = useFormContext<CaseUiData>();

  // Watchers for interrelated fields
  const {
    clinician: watchClinician,
    useClinicianForProcedureSite: watchUseClinicianForProcedureSite,
  } = watch();

  const fillProcedureSiteFromClinician = useCallback(
    (checked?: boolean): void => {
      if (checked) {
        const clinician = clinicians.find((c) => c.value === watchClinician);
        // Never write [Check LIMS] into the practitionerLocation field
        if (clinician && clinician.label !== CHECK_LIMS) {
          setValue("practitionerLocation", clinician.label, setValueConfig);
        }
      }
    },
    [clinicians, watchClinician, setValue]
  );

  // Force to false if current form doesn't allow clinician to be used as procedure site
  useEffect(() => {
    if (!allowUseClinicianForProcedureSite) {
      setValue("useClinicianForProcedureSite", false, setValueConfig);
    }
  }, [allowUseClinicianForProcedureSite, setValue]);

  // Update practitioner (procedure) location field when clinician or checkbox changes
  useEffect(() => {
    fillProcedureSiteFromClinician(watchUseClinicianForProcedureSite);
  }, [watchClinician, watchUseClinicianForProcedureSite, fillProcedureSiteFromClinician]);

  if (allowUseClinicianForProcedureSite) {
    return (
      <FormCheckbox
        addMargin
        id="useClinicianForProcedureSite"
        label="Use clinician as procedure site"
        disabled={!watchClinician}
        {...register("useClinicianForProcedureSite")}
      />
    );
  } else {
    return (
      <>
        <br />
        <input
          type="hidden"
          id="useClinicianForProcedureSite"
          {...register("useClinicianForProcedureSite", { value: false })}
        />
      </>
    );
  }
};

export default UseClinicianForProcedureSite;
