import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CURRENT_YEAR } from "../../../helpers/strings";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface DeviceIdForFailedAttemptProps {
  disabled: boolean;
}

const DeviceIdForFailedAttempt = ({
  disabled,
}: DeviceIdForFailedAttemptProps): React.JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <FormInput
      addMargin
      id="deviceIdForFailedAttempt"
      label="Cell collection device ID for failed attempt"
      disabled={disabled}
      icon={faQrcode}
      placeholder={`(10)${CURRENT_YEAR}000001`}
      help="Optional (EndoSign only)"
      error={formState.errors.deviceIdForFailedAttempt}
      {...register("deviceIdForFailedAttempt")}
    />
  );
};

export default DeviceIdForFailedAttempt;
