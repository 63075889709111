import classNames from "classnames";
import { clamp, compact, flatten } from "lodash";
import React from "react";
import zxcvbn, { ZXCVBNResult, ZXCVBNScore } from "zxcvbn";

export const TEST_ID_PASSWORD_STRENGTH_BAR = "PasswordStrengthBar";
export const TEST_ID_PASSWORD_STRENGTH_FEEDBACK = "PasswordStrengthFeedback";

export const MINIMUM_PASSWORD_LENGTH = 8;

interface PasswordStrengthProps {
  password: string;
}

const scoreDescriptions: Record<ZXCVBNScore, string> = {
  0: `Must have at least ${MINIMUM_PASSWORD_LENGTH} characters`,
  1: "Weak",
  2: "Okay",
  3: "Good",
  4: "Strong",
};

const scoreColours: Record<ZXCVBNScore, string> = {
  0: "",
  1: "is-danger",
  2: "is-warning",
  3: "is-link",
  4: "is-success",
};

const getPasswordStrength = (password: string): ZXCVBNResult => {
  // To bound runtime latency for really long passwords, send zxcvbn() only the
  // first 100 characters of user input
  return zxcvbn(password.substring(0, 100));
};

const PasswordStrength = ({ password }: PasswordStrengthProps): React.JSX.Element => {
  const {
    score: rawScore,
    feedback: { warning, suggestions },
  } = getPasswordStrength(password);

  // Hold score at zero until the password is long enough, and then clamp the
  // score between 1 and 4 to prevent the progress bar from returning to empty
  const isMinimumLength = password.length >= MINIMUM_PASSWORD_LENGTH;
  const score = !isMinimumLength ? 0 : (clamp(rawScore, 1, 4) as ZXCVBNScore);

  // Flatten all feedback into a single array of messages, omitting empty strings
  const feedback: string[] = compact(
    flatten([scoreDescriptions[score], warning, suggestions])
  );

  return (
    <div className="mt-3">
      <progress
        max={4}
        value={score}
        className={classNames("progress is-small mb-2", scoreColours[score])}
        data-testid={TEST_ID_PASSWORD_STRENGTH_BAR}
      />
      <ul data-testid={TEST_ID_PASSWORD_STRENGTH_FEEDBACK}>
        {feedback.map((message) => (
          <li key={message} className="help">
            {message}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PasswordStrength;
