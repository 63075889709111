import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

const HasRefluxSymptoms = (): React.JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="hasRefluxSymptoms"
      label="Does the patient currently have heartburn/reflux symptoms?"
      options={CaseSchemaOptions.yesNoUnanswered}
      error={formState.errors.hasRefluxSymptoms}
      {...register("hasRefluxSymptoms")}
    />
  );
};

export default HasRefluxSymptoms;
