import { yupResolver } from "@hookform/resolvers/yup";
import { mergeWith } from "lodash";
import React, { ForwardedRef, useEffect, useImperativeHandle, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { RequestFormProps, getRequestFormComponent } from "./FormComponent";
import { RequestFormVersion } from "./blocks";
import { SubmitButton } from "./layouts";
import { CaseUiData, CaseUiSchema } from "./schema/CaseUiSchema";
import { RequestFormType } from "./schema/RequestForms";

export type FormMode = "create" | "update";
export type FormRef = { submitForm: () => void };

export const setValueConfig = {
  shouldValidate: true,
  shouldDirty: true,
  shouldTouch: true,
};

// Ensure that unchecked checkboxes are initialised for new cases,
// and when any newer fields are null for historic cases.
const defaultFormValues: Partial<CaseUiData> = Object.freeze({
  patientIdentifierNotProvided: false,
  patientDateOfBirthIsYearOnly: false,
  useRecordNumberForPatientName: false,
  useClinicianForProcedureSite: false,
  hasAdditionalClinicalInformation: false,
});

const FormWrapper = React.forwardRef<FormRef, RequestFormProps>(
  (
    { formMode, formLocked, formValues, limsOptions, onSubmit }: RequestFormProps,
    ref: ForwardedRef<FormRef>
  ): React.JSX.Element => {
    // Allow parent component to trigger form submission
    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    const defaultValues: CaseUiData = useMemo(() => {
      return mergeWith(
        formValues,
        defaultFormValues,
        (existingValue) => existingValue ?? undefined
      );
    }, [formValues]);

    // Preserve all hook methods to spread into the FormProvider context
    const methods = useForm<CaseUiData>({
      mode: "onTouched",
      defaultValues,
      resolver: yupResolver(CaseUiSchema),
    });

    // Destructure some of those hook methods for use in this component
    const { handleSubmit, reset, watch } = methods;

    // Reset the form when case data from parent component changes
    useEffect(() => reset(defaultValues), [reset, defaultValues]);

    // Fetch the form component corresponding to the selected TRF version
    const formType = watch("requestFormType") as RequestFormType;
    const formProps = { formMode, formLocked, limsOptions, onSubmit };
    const { FormComponent } = getRequestFormComponent(formType);

    return (
      <FormProvider {...methods}>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <RequestFormVersion formMode={formMode} disabled={formLocked} />
          <FormComponent {...formProps} />
          {!!formType && <SubmitButton formMode={formMode} formLocked={formLocked} />}
        </form>
      </FormProvider>
    );
  }
);

FormWrapper.displayName = "FormWrapper";

export default FormWrapper;
