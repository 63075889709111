import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface PatientFirstNameProps {
  onChange?: () => void;
}

const PatientFirstName = ({ onChange }: PatientFirstNameProps): React.JSX.Element => {
  const { register, formState, watch } = useFormContext<CaseUiData>();
  const { useRecordNumberForPatientName } = watch();

  return (
    <FormInput
      id="patientFirstName"
      label="Name"
      readOnly={useRecordNumberForPatientName}
      error={formState.errors.patientFirstName}
      {...register("patientFirstName", { onChange })}
    />
  );
};

export default PatientFirstName;
