import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface PatientSurnameProps {
  onChange?: () => void;
}

const PatientSurname = ({ onChange }: PatientSurnameProps): React.JSX.Element => {
  const { register, formState, watch } = useFormContext<CaseUiData>();
  const { useRecordNumberForPatientName } = watch();

  return (
    <FormInput
      id="patientSurname"
      label="Surname"
      readOnly={useRecordNumberForPatientName}
      error={formState.errors.patientSurname}
      {...register("patientSurname", { onChange })}
    />
  );
};

export default PatientSurname;
