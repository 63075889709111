import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import Fieldset from "../../components/forms/Fieldset";
import { RequestFormProps } from "../FormComponent";
import {
  BarrettsDetails,
  DeviceDetails,
  LabUseOnly,
  PatientDetails,
  ReportRecipient,
} from "../blocks";
import { ProcedureDetails } from "../blocks/deprecated";
import { HasAdditionalClinicalInformation } from "../fields/clinical";
import { CaseUiData } from "../schema/CaseUiSchema";

const TRF_2_Version_2 = ({
  formMode,
  formLocked,
  limsOptions: { specimens, clinicians, caseOrigins, consultants },
}: RequestFormProps): React.JSX.Element => {
  const { unregister } = useFormContext<CaseUiData>();

  useEffect(() => {
    // Discard any fields in form state which aren't used in this form
    unregister([
      "confidentSpongeReachedStomach",
      "hasRefluxSymptoms",
      "hasEoEDiagnosis",
      "deviceIdForFailedAttempt",
      "reasonsForFailedAttempt",
    ]);
  }, [unregister]);

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <DeviceDetails
            allowUseRecordNumberForPatientName
            disabled={formLocked}
            specimens={specimens}
          />
        </div>
        <div className="tile is-parent">
          <ReportRecipient
            allowUseClinicianForProcedureSite
            disabled={formLocked}
            clinicians={clinicians}
            caseOrigins={caseOrigins}
          />
        </div>
      </div>

      <PatientDetails disabled={formLocked} />

      <Fieldset title="Clinical information" disabled={formLocked}>
        <BarrettsDetails disabled={formLocked} />
        <HasAdditionalClinicalInformation />
      </Fieldset>

      <ProcedureDetails formMode={formMode} formLocked={formLocked} />

      <LabUseOnly
        forceP53
        formMode={formMode}
        disabled={formLocked}
        consultants={consultants}
      />
    </>
  );
};

export default TRF_2_Version_2;
