import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { setValueConfig } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

const HadDysplasia = (): React.JSX.Element => {
  const { register, formState, setValue } = useFormContext<CaseUiData>();

  return (
    <RadioGroup
      id="hadDysplasia"
      label="Has the patient ever had dysplasia?"
      options={CaseSchemaOptions.yesNoUnknownUnanswered}
      error={formState.errors.hadDysplasia}
      {...register("hadDysplasia", {
        // Reset follow-up question
        onChange: () => setValue("dysplasiaGrade", null, setValueConfig),
      })}
    />
  );
};

export default HadDysplasia;
