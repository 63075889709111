import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const TEST_ID_LIMS_CASE_LINK = "LimsCaseLink";

interface LimsCaseLinkProps {
  limsCaseId?: string | number | null;
  className?: string;
  dataTestId?: string;
}

const LimsCaseLink = ({
  limsCaseId,
  className,
  dataTestId,
}: LimsCaseLinkProps): React.JSX.Element | null => {
  const limsCaseUrl = `${import.meta.env.VITE_APP_LIMS_URL}/Casebook/CheckIn?CaseID=${limsCaseId}`;

  if (limsCaseId === null || limsCaseId === undefined || limsCaseId === "") return null;
  return (
    <a
      href={limsCaseUrl}
      target="_blank"
      rel="noreferrer"
      className={className}
      data-testid={dataTestId ?? TEST_ID_LIMS_CASE_LINK}
    >
      <FontAwesomeIcon icon={faExternalLink} className="mr-2" />
      Open in LIMS
    </a>
  );
};

export default LimsCaseLink;
