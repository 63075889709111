import {
  faBackwardStep,
  faCaretLeft,
  faCaretRight,
  faForwardStep,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const TEST_ID_PAGINATION_FIRST_PAGE = "PaginationFirstPage";
export const TEST_ID_PAGINATION_PREVIOUS_PAGE = "PaginationPreviousPage";
export const TEST_ID_PAGINATION_NEXT_PAGE = "PaginationNextPage";
export const TEST_ID_PAGINATION_LAST_PAGE = "PaginationLastPage";
export const TEST_ID_PAGINATION_LABEL = "PaginationLabel";
export const TEST_ID_PAGINATION_COMPONENT = "PaginationComponent";

export interface PaginationProps {
  page: number;
  totalPages: number;
  onPageChange: (x: number) => void;
}

const Pagination = ({
  page,
  totalPages,
  onPageChange,
}: PaginationProps): React.JSX.Element => {
  return (
    <div className="cy-pagination" data-testid={TEST_ID_PAGINATION_COMPONENT}>
      <div className="field has-addons is-align-items-center">
        <div className="control">
          <button
            type="button"
            className="cy-pagination__button"
            aria-label="Go to first page"
            data-testid={TEST_ID_PAGINATION_FIRST_PAGE}
            disabled={page === 1}
            onClick={() => onPageChange(1)}
          >
            <FontAwesomeIcon icon={faBackwardStep} size="sm" />
          </button>
        </div>
        <div className="control">
          <button
            type="button"
            className="cy-pagination__button"
            aria-label="Go to previous page"
            data-testid={TEST_ID_PAGINATION_PREVIOUS_PAGE}
            disabled={page === 1}
            onClick={() => onPageChange(page - 1)}
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </button>
        </div>
        <div
          className="control cy-pagination__label"
          data-testid={TEST_ID_PAGINATION_LABEL}
        >
          Page {page} of {totalPages}
        </div>
        <div className="control">
          <button
            type="button"
            className="cy-pagination__button"
            aria-label="Go to next page"
            data-testid={TEST_ID_PAGINATION_NEXT_PAGE}
            disabled={page === totalPages}
            onClick={() => onPageChange(page + 1)}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </button>
        </div>
        <div className="control">
          <button
            type="button"
            className="cy-pagination__button"
            aria-label="Go to last page"
            data-testid={TEST_ID_PAGINATION_LAST_PAGE}
            disabled={page === totalPages}
            onClick={() => onPageChange(totalPages)}
          >
            <FontAwesomeIcon icon={faForwardStep} size="sm" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
