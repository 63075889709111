import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { ChangeEvent } from "react";
import DatePicker from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import { Control, Controller, FieldError, FieldValues, Path } from "react-hook-form";

type DateInputMode = "date" | "month" | "year";

type DatePickerInputElement = ReactDatePicker & { input: HTMLInputElement };

interface FormDateInputProps<T extends FieldValues> {
  id: string;
  label: string;
  mode?: DateInputMode;
  minDate?: Date;
  maxDate?: Date;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  todayButton?: boolean;
  addMargin?: boolean;
  disabled?: boolean;
  control: Control<T>;
  help?: string;
  error?: FieldError;
}

const dateFormats: { [key in DateInputMode]: string } = {
  date: "dd/MM/yyyy",
  month: "MM/yyyy",
  year: "yyyy",
};

const datePlaceholder: { [key in DateInputMode]: string } = {
  date: "dd/mm/yyyy",
  month: "mm/yyyy",
  year: "Select...",
};

const FormDateInput = <T extends FieldValues>({
  id,
  label,
  mode = "date",
  minDate,
  maxDate = new Date(),
  showMonthDropdown,
  showYearDropdown,
  todayButton,
  addMargin,
  disabled = false,
  control,
  help,
  error,
}: FormDateInputProps<T>): React.JSX.Element => {
  return (
    <div className={classNames("field", { "mb-5": addMargin, disabled })}>
      <label htmlFor={id} className="label">
        {label}
      </label>
      <div className="control has-icons-right">
        <Controller
          name={id as Path<T>}
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <>
              <DatePicker
                id={id}
                name={id}
                disabled={disabled}
                onChange={(date) => onChange(date as unknown as ChangeEvent)}
                onBlur={onBlur}
                selected={value}
                minDate={minDate}
                maxDate={maxDate}
                dateFormat={dateFormats[mode]}
                placeholderText={datePlaceholder[mode]}
                showYearPicker={mode === "year"}
                showMonthYearPicker={mode === "month"}
                showMonthDropdown={showMonthDropdown}
                showYearDropdown={showYearDropdown}
                dropdownMode="select"
                todayButton={todayButton ? "Today" : undefined}
                className={classNames("input", { "is-danger": error })}
                // Directly assign the ref to the HTML input element so that
                // React Hook Form can set focus when field is invalid
                ref={(el: DatePickerInputElement | null) => ref(el?.input)}
              />
              <span className="icon is-small is-right">
                <FontAwesomeIcon icon={faCalendarDays} />
              </span>
            </>
          )}
        />
      </div>
      {help && !error && <p className="help">{help}</p>}
      {error && <p className="help has-text-danger">{error.message}</p>}
    </div>
  );
};

export default FormDateInput;
