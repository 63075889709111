import React from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import FormSelect, { FormSelectOption } from "../../../components/forms/FormSelect";
import {
  selectActivePathologistOptions,
  selectAllPathologistOptions,
  selectPathologists,
} from "../../../store/metadataSlice";
import { FormMode, setValueConfig } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { LimsConsultant } from "../lims";

interface ConsultantProps {
  formMode: FormMode;
  formLocked: boolean;
  consultants: FormSelectOption[];
}

const Pathologist = ({
  formMode,
  formLocked,
  consultants,
}: ConsultantProps): React.JSX.Element => {
  const { register, control, formState, setValue } = useFormContext<CaseUiData>();

  // Redux
  const pathologists = useSelector(selectPathologists);

  // Only offer active pathologists when booking in new cases, but include all
  // pathologists when editing cases. This ensures that deactivated pathologists
  // assigned to historic cases are still visible in the dropdown list.
  const pathologistOptions: Record<FormMode, FormSelectOption[]> = {
    create: useSelector(selectActivePathologistOptions),
    update: useSelector(selectAllPathologistOptions),
  };

  const setLimsConsultant = (userId?: string): void => {
    const match = pathologists.find(({ pathkitUserId }) => userId === pathkitUserId);
    if (match?.limsConsultantId) {
      setValue("consultant", match.limsConsultantId, setValueConfig);
    }
  };

  return (
    <>
      <FormSelect
        id="pathologistId"
        label="Reporting pathologist"
        disabled={formLocked}
        control={control}
        options={pathologistOptions[formMode]}
        error={formState.errors.pathologistId}
        onChange={(pathologist) => {
          const { label: fullName, value: userId } = pathologist || {};
          setLimsConsultant(userId);
          setValue("consultantName", fullName ?? "", setValueConfig);
        }}
      />
      <LimsConsultant consultants={consultants} />
      <input type="hidden" id="consultantName" {...register("consultantName")} />
    </>
  );
};

export default Pathologist;
