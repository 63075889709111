import React from "react";
import { useFormContext } from "react-hook-form";

import FormCheckbox from "../../../components/forms/FormCheckbox";
import FormDateInput from "../../../components/forms/FormDateInput";
import { CaseUiData } from "../../schema/CaseUiSchema";

const PatientDateOfBirth = (): React.JSX.Element => {
  const { register, control, formState, watch } = useFormContext<CaseUiData>();
  const { patientDateOfBirthIsYearOnly } = watch();

  return (
    <>
      <FormDateInput
        id="patientDateOfBirth"
        label={`${patientDateOfBirthIsYearOnly ? "Year" : "Date"} of birth`}
        showMonthDropdown
        showYearDropdown
        mode={patientDateOfBirthIsYearOnly ? "year" : "date"}
        control={control}
        error={formState.errors.patientDateOfBirth}
      />
      <FormCheckbox
        id="patientDateOfBirthIsYearOnly"
        label="Year only"
        {...register("patientDateOfBirthIsYearOnly")}
      />
    </>
  );
};

export default PatientDateOfBirth;
