import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { isEmpty, mapValues, pickBy, trim } from "lodash";

import { RootState } from ".";
import { CaseUiData } from "../forms/schema/CaseUiSchema";
import {
  CaseListResult,
  CaseStateTransitionResponse,
  CaseStatusFilter,
  GetCasesResponse,
} from "../schemas/ApiSchema";
import { CustomError, dataService } from "../services/data.service";

export type SearchParameters = {
  [K in keyof CaseUiData]?: string;
} & {
  status?: string;
};

export interface CaseListState {
  status: "pending" | "succeeded" | "failed";
  error?: CustomError;
  lastUpdated: number;
  results: CaseListResult[];
  total: number;
  searchParameters: SearchParameters;
  totalPages: number;
  page: number;
}

type FetchCasesPayload = {
  data: GetCasesResponse;
  requestTimestamp: number;
};

const initialState: CaseListState = {
  status: "pending",
  error: undefined,
  lastUpdated: 0,
  results: [],
  total: 0,
  searchParameters: {
    // Keep in sync with the fields in CaseSearch.tsx
    labNumber: "",
    recordNumber: "",
    userGroupId: "",
    status: CaseStatusFilter.ALL,
    patientFirstName: "",
    patientSurname: "",
    patientIdentifier: "",
  },
  totalPages: 1,
  page: 1,
};

export const fetchCases = createAsyncThunk<
  FetchCasesPayload,
  void,
  {
    rejectValue: CustomError;
    state: RootState;
  }
>("search/fetchCases", async (_, { getState, rejectWithValue }) => {
  const state = getState();
  const requestTimestamp = Date.now();
  const response = await dataService.getCases(
    state.caseList.page,
    selectSearchParameters(state)
  );
  if (response.data) {
    return { data: response.data, requestTimestamp };
  } else {
    return rejectWithValue(response.error);
  }
});

export const caseListSlice = createSlice({
  name: "caseList",
  initialState,
  reducers: {
    updateSearchParameter: (
      state,
      action: PayloadAction<{ key: keyof SearchParameters; value?: string }>
    ) => {
      const { key, value } = action.payload;
      state.page = 1;
      state.searchParameters[key] = value ?? "";
    },
    removeSearchParameter: (state, action: PayloadAction<keyof SearchParameters>) => {
      state.page = 1;
      state.searchParameters[action.payload] = "";
    },
    updatePageNumber: (state, action: PayloadAction<number>) => {
      if (action.payload > 0 && action.payload <= state.totalPages) {
        state.page = action.payload;
      }
    },
    updateCaseListResult: (
      state,
      action: PayloadAction<{
        labNumber: string;
        data: CaseStateTransitionResponse;
      }>
    ) => {
      const { labNumber, data } = action.payload;
      const caseListResult = state.results.find(
        ({ caseData }) => caseData.labNumber === labNumber
      );
      if (caseListResult) {
        caseListResult.caseState = data.newState;
        caseListResult.permissions = data.permissions;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCases.pending, (state) => {
        state.error = undefined;
        state.status = "pending";
      })
      .addCase(
        fetchCases.fulfilled,
        (state, action: PayloadAction<FetchCasesPayload>) => {
          const {
            data: { cases, totalCases, totalPages, page },
            requestTimestamp,
          } = action.payload;
          // Update the store only if the action's timestamp is newer
          if (requestTimestamp > state.lastUpdated) {
            state.lastUpdated = requestTimestamp;
            state.status = "succeeded";
            state.results = cases;
            state.total = totalCases;
            state.totalPages = totalPages;
            state.page = page;
          }
        }
      )
      .addCase(fetchCases.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Actions
export const {
  updateSearchParameter,
  removeSearchParameter,
  updatePageNumber,
  updateCaseListResult,
} = caseListSlice.actions;

// Selector function to trim and filter empty strings from search parameters
export const selectSearchParameters = createSelector(
  (state: RootState) => state.caseList.searchParameters,
  (searchParameters): SearchParameters =>
    pickBy(mapValues(searchParameters, trim), (value) => !isEmpty(value))
);

export default caseListSlice.reducer;
