import {
  faArrowLeft,
  faArrowRight,
  faClock,
  faEdit,
  faLock,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { format } from "date-fns";
import { find } from "lodash";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router";

import { getCaseStateLabel } from "../../helpers/case";
import {
  CaseListResultData,
  CasePermissions,
  CaseState,
  CaseStateTransition,
  CaseTransition,
} from "../../schemas/ApiSchema";
import { dataService } from "../../services/data.service";
import { AppDispatch } from "../../store";
import { fetchCases, updateCaseListResult } from "../../store/caseListSlice";
import {
  selectLimsOptionLabel,
  selectPathologistLabel,
  selectUserGroupLabel,
} from "../../store/metadataSlice";
import LimsCaseLink from "../common/LimsCaseLink";

export const TEST_ID_CASE_CARD_LIMS_LINK = "CaseCardLimsLink";
export const TEST_ID_CASE_CARD_EDIT_LINK = "CaseCardEditLink";

export interface CaseCardProps {
  item: CaseListResultData;
  caseState: CaseState;
  permissions: CasePermissions;
}

const CaseCard = ({
  item,
  caseState,
  permissions: { canEditCase, authorisationBlockers },
}: CaseCardProps): React.JSX.Element => {
  const {
    labNumber,
    recordNumber,
    userGroupId,
    pathologistId,
    clinician,
    caseOrigin,
    consultant,
    limsCaseId,
    patientFirstName,
    patientSurname,
    patientIdentifier,
  } = item;
  const patientFullName = patientFirstName + " " + patientSurname;
  const dateReceived = "Received " + format(item.dateReceived, "d MMMM yyyy");

  const { enqueueSnackbar } = useSnackbar();

  // Local state
  const [busy, setBusy] = useState<boolean>(false);

  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const userGroupName = useSelector(selectUserGroupLabel(userGroupId));
  const pathologistName = useSelector(selectPathologistLabel(pathologistId));
  const clinicianName = useSelector(selectLimsOptionLabel("clinicians", clinician));
  const caseOriginName = useSelector(selectLimsOptionLabel("caseOrigins", caseOrigin));
  const consultantName = useSelector(selectLimsOptionLabel("consultants", consultant));

  // Manual case state transitions
  const { AWAITING_SLIDES, READY_FOR_PATHOLOGIST, REPORT_SUBMITTED } = CaseState;
  const caseTransitions: CaseStateTransition[] = [
    {
      transition: "allow-reporting",
      allowedWhen: [AWAITING_SLIDES],
      icon: faArrowRight,
      label: "Remove case from lab",
      successMessage: `Removed ${labNumber} from lab`,
    },
    {
      transition: "prevent-reporting",
      allowedWhen: [READY_FOR_PATHOLOGIST, REPORT_SUBMITTED],
      icon: faArrowLeft,
      label: "Move case to lab",
      successMessage: `Moved ${labNumber} to lab`,
    },
  ];
  const allowedTransitions = caseTransitions.filter(({ allowedWhen }) =>
    allowedWhen.includes(caseState)
  );

  const CaseStatusTag = (): React.JSX.Element => {
    const caseStateLabel = getCaseStateLabel(caseState, authorisationBlockers);
    return (
      <span
        className={classNames("tag is-rounded", { "is-link is-light": canEditCase })}
        data-testid={`${labNumber}CaseCardStatusTag`}
      >
        <FontAwesomeIcon className="mr-2" icon={canEditCase ? faClock : faLock} />
        {caseStateLabel}
      </span>
    );
  };

  const CaseTransitionButtons = (): React.JSX.Element | null => {
    if (allowedTransitions.length === 0) return null;
    return (
      <div>
        {allowedTransitions.map(({ transition, icon, label }) => (
          <button
            key={transition}
            type="button"
            disabled={busy}
            className="button is-link is-inverted"
            onClick={() => moveCase(transition)}
            data-testid={`${labNumber}CaseCard${transition}Button`}
          >
            <FontAwesomeIcon
              className="mr-2"
              spin={busy}
              icon={busy ? faRefresh : icon}
            />
            {label}
          </button>
        ))}
      </div>
    );
  };

  const moveCase = async (transition: CaseTransition): Promise<void> => {
    const operation = find(allowedTransitions, ["transition", transition]);
    if (!operation) return;
    setBusy(true);
    // Try to fetch the case version ID
    const { data, error } = await dataService.getCase(labNumber);
    const { caseVersionId } = data?.caseInfo ?? {};

    // Then try to transition the case
    if (caseVersionId) {
      const { data, error } = await dataService.transitionCase(
        transition,
        labNumber,
        caseVersionId
      );
      if (data) {
        dispatch(fetchCases()); // Success, so refresh the case list
        dispatch(updateCaseListResult({ labNumber, data })); // But update case card instantly
        enqueueSnackbar(operation.successMessage, { variant: "success" });
      }
      if (error) {
        enqueueSnackbar(error.msg, { variant: "error" });
      }
      setBusy(false);
    }

    // If fetching the case version ID failed, show an error
    if (error) {
      enqueueSnackbar(error.msg, { variant: "error" });
      setBusy(false);
    }
  };

  return (
    <li
      className={classNames("cy-case-card", { "cy-case-card--locked": !canEditCase })}
      data-testid={`${labNumber}CaseCard`}
    >
      <Link to={labNumber} className="cy-case-card__content">
        <div className="columns is-mobile">
          <div className="column">
            <h4 className="title is-5">{item.labNumber}</h4>
          </div>
          <div className="tags" data-testid={`${labNumber}CaseCardTags`}>
            <span className="tag is-rounded">{dateReceived}</span>
            <CaseStatusTag />
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-3">
            <p className="cy-case-card__label">Kit ID</p>
            <p className="cy-case-card__value">{recordNumber}</p>
          </div>
          <div className="column is-3">
            <p className="cy-case-card__label">Patient name</p>
            <p className="cy-case-card__value">{patientFullName}</p>
          </div>
          <div className="column is-6">
            <p className="cy-case-card__label">User group</p>
            <p className="cy-case-card__value">{userGroupName}</p>
          </div>
          <div className="column is-3">
            <p className="cy-case-card__label">NHS/CHI number</p>
            <p className="cy-case-card__value">{patientIdentifier}</p>
          </div>
          <div className="column is-3">
            <p className="cy-case-card__label">Reporting pathologist</p>
            <p className="cy-case-card__value">{pathologistName || consultantName}</p>
          </div>
          <div className="column is-6">
            <p className="cy-case-card__label">Clinician / Case origin</p>
            <p className="cy-case-card__value">{clinicianName}</p>
            <p className="cy-case-card__value">{caseOriginName}</p>
          </div>
        </div>
      </Link>
      <div className="buttons are-small is-justify-content-space-between px-2 py-3">
        <div>
          <Link
            to={labNumber}
            className="button is-ghost"
            data-testid={`${labNumber}CaseCardEditLink`}
          >
            <FontAwesomeIcon icon={faEdit} className="mr-2" /> Edit
          </Link>
          <LimsCaseLink
            limsCaseId={limsCaseId}
            className="button is-ghost"
            dataTestId={`${labNumber}CaseCardLimsLink`}
          />
        </div>
        <CaseTransitionButtons />
      </div>
    </li>
  );
};

export default CaseCard;
