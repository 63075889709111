import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortBy } from "lodash";
import React, { CSSProperties, useEffect, useState } from "react";

import { CustomError, dataService } from "../../services/data.service";

export const TEST_ID_SLIDE_LINKS_LIST = "SlideLinksList";
export const TEST_ID_SLIDE_LINKS_MESSAGE = "SlideLinksMessage";

export interface SlideLink {
  url: string;
  label: string;
}

interface SlideLinksProps {
  labNumber: string;
}

const messageStyles: CSSProperties = {
  fontStyle: "italic",
  marginBottom: "1.25rem",
  marginLeft: "1.875rem",
};

const listStyles: CSSProperties = {
  listStyleType: "none",
  marginBottom: "1.25rem",
  marginLeft: "1.875rem",
  marginTop: 0,
};

const SlideLinks = ({ labNumber }: SlideLinksProps): React.JSX.Element => {
  const [busy, setBusy] = useState<boolean>(true);
  const [error, setError] = useState<CustomError>();
  const [slideLinks, setSlideLinks] = useState<SlideLink[]>();

  const noSlides: boolean = slideLinks?.length === 0;
  const hasSlides: boolean = !!slideLinks && slideLinks.length > 0;

  useEffect(() => {
    const fetchSlides = async () => {
      const response = await dataService.getSlides(labNumber);
      if (response.data) {
        setSlideLinks(response.data);
      } else {
        setError(response.error);
      }
      setBusy(false);
    };
    fetchSlides();
  }, [labNumber]);

  const SlideLink = ({ slide }: { slide: SlideLink }): React.JSX.Element => {
    return (
      <li>
        <a href={slide.url} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faExternalLink} size="sm" className="mr-1" />{" "}
          {slide.label}
        </a>
      </li>
    );
  };

  return (
    <>
      {hasSlides ? (
        <ul style={listStyles} data-testid={TEST_ID_SLIDE_LINKS_LIST}>
          {sortBy(slideLinks, ["label"]).map((slide, i) => (
            <SlideLink key={`slide-${i}`} slide={slide} />
          ))}
        </ul>
      ) : (
        <div style={messageStyles} data-testid={TEST_ID_SLIDE_LINKS_MESSAGE}>
          {busy && <p>Loading slides...</p>}
          {noSlides && <p>No slides found for {labNumber}</p>}
          {!!error && <p className="has-text-danger">{error.msg}</p>}
        </div>
      )}
    </>
  );
};

export default SlideLinks;
