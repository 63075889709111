import React from "react";
import { useFormContext } from "react-hook-form";

import Fieldset from "../../../components/forms/Fieldset";
import { FormMode, setValueConfig } from "../../FormWrapper";
import {
  DidPatientSwallow,
  DidSpongeExpand,
  IsDebrisOnSponge,
  NumberOfFailedAttempts,
  TakesAnticoagulants,
  WasTensionOnThread,
} from "../../fields/deprecated";
import {
  IsBloodOnSponge,
  PractitionerLocation,
  PractitionerName,
  ProcedureDate,
} from "../../fields/procedure";
import NoIssuesButton from "../../fields/procedure/NoIssuesButton";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { Answer } from "../../schema/FormAnswers";

interface ProcedureDetailsProps {
  formMode: FormMode;
  formLocked: boolean;
}

const ProcedureDetails = ({
  formMode,
  formLocked,
}: ProcedureDetailsProps): React.JSX.Element => {
  const { setValue } = useFormContext<CaseUiData>();

  const handleClick = (): void => {
    setValue("didPatientSwallow", Answer.YES, setValueConfig);
    setValue("didSpongeExpand", Answer.YES, setValueConfig);
    setValue("wasTensionOnThread", Answer.YES, setValueConfig);
    setValue("isDebrisOnSponge", Answer.NO, setValueConfig);
    setValue("isBloodOnSponge", Answer.NO, setValueConfig);
    setValue("takesAnticoagulants", null, setValueConfig);
  };

  return (
    <Fieldset
      title="Procedure details"
      disabled={formLocked}
      addon={<NoIssuesButton formLocked={formLocked} handleClick={handleClick} />}
    >
      <div className="columns">
        <div className="column is-half">
          <DidPatientSwallow />
          <DidSpongeExpand />
          <WasTensionOnThread />
          <IsDebrisOnSponge />
          <IsBloodOnSponge />
          <TakesAnticoagulants />
        </div>
        <div className="column is-half">
          <PractitionerName />
          <PractitionerLocation />
          <div className="columns is-mobile">
            <div className="column is-half">
              <ProcedureDate formMode={formMode} />
            </div>
          </div>
          <NumberOfFailedAttempts />
        </div>
      </div>
    </Fieldset>
  );
};

export default ProcedureDetails;
