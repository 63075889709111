import { forEach, isEqual } from "lodash";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { CaseStatusFilterLabels } from "../../helpers/case";
import { CaseStatusFilter } from "../../schemas/ApiSchema";
import { AppDispatch, RootState } from "../../store";
import { SearchParameters, updateSearchParameter } from "../../store/caseListSlice";
import { selectAllUserGroupOptions } from "../../store/metadataSlice";
import FormInput from "../forms/FormInput";
import FormSelect, { FormSelectOption } from "../forms/FormSelect";
import SearchPanel from "../search/SearchPanel";

const caseStatusOptions: FormSelectOption[] = Object.values(CaseStatusFilter).map(
  (value) => ({
    label: CaseStatusFilterLabels[value],
    value,
  })
);

const CaseSearch = (): React.JSX.Element => {
  // Redux
  const { searchParameters } = useSelector((state: RootState) => state.caseList);
  const userGroupOptions = useSelector(selectAllUserGroupOptions);
  const dispatch = useDispatch<AppDispatch>();

  // Initially populate the search form with any search parameters in Redux
  const { register, control, watch, getValues, setValue } = useForm<SearchParameters>({
    defaultValues: searchParameters,
  });

  // Prevent duplicate API requests when search parameters are changed elsewhere
  const externalChange = useRef(false);

  // Handle internal changes, eg. typing in a field or selecting a dropdown option
  useEffect(() => {
    const handleChange = (key: keyof SearchParameters) => {
      const value = getValues(key);
      dispatch(updateSearchParameter({ key, value }));
    };
    const subscription = watch((form, { name }) => {
      if (name && !externalChange.current) {
        handleChange(name);
      }
    });
    return () => subscription.unsubscribe();
  }, [dispatch, getValues, watch]);

  // Handle external changes, eg. deleting a search tag
  useEffect(() => {
    const shouldUpdateFieldsFromStore = !isEqual(searchParameters, getValues());
    if (shouldUpdateFieldsFromStore) {
      externalChange.current = true;
      forEach(searchParameters, (value, key) =>
        setValue(key as keyof SearchParameters, value)
      );
      externalChange.current = false;
    }
  }, [searchParameters, getValues, setValue]);

  return (
    <form autoComplete="off">
      <h3 className="title is-4 mb-2">Search</h3>
      <SearchPanel expanded title="Case data">
        <div className="columns is-multiline is-variable is-2 mb-3">
          <div className="column is-full is-half-fullhd">
            <FormInput
              id="labNumber"
              label="Case number"
              maxLength={10}
              className="is-uppercase"
              {...register("labNumber", {
                setValueAs: (value: string) => value.toUpperCase(),
              })}
            />
          </div>
          <div className="column is-full is-half-fullhd">
            <FormInput
              id="recordNumber"
              label="Kit ID"
              maxLength={8}
              className="is-uppercase"
              {...register("recordNumber", {
                setValueAs: (value: string) => value.toUpperCase(),
              })}
            />
          </div>
        </div>
        <FormSelect
          clearable
          id="userGroupId"
          label="User group"
          control={control}
          options={userGroupOptions}
        />
        <FormSelect
          id="status"
          label="Status"
          control={control}
          options={caseStatusOptions}
        />
      </SearchPanel>

      <SearchPanel expanded title="Patient details">
        <div className="columns is-multiline is-variable is-2 mb-3">
          <div className="column is-full is-half-fullhd">
            <FormInput
              id="patientFirstName"
              label="First name"
              {...register("patientFirstName")}
            />
          </div>
          <div className="column is-full is-half-fullhd">
            <FormInput
              id="patientSurname"
              label="Surname"
              {...register("patientSurname")}
            />
          </div>
          <div className="column is-full is-half-fullhd">
            <FormInput
              id="patientIdentifier"
              label="NHS/CHI number"
              {...register("patientIdentifier")}
            />
          </div>
        </div>
      </SearchPanel>
    </form>
  );
};

export default CaseSearch;
