import { signOut } from "aws-amplify/auth";
import { useSnackbar } from "notistack";
import { MouseEvent } from "react";
import { useNavigate } from "react-router";

import { SITE_TITLE } from "./strings";

export const useHandleSignOut = () => {
  const { closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSignOut = async (e: MouseEvent) => {
    e.preventDefault();
    try {
      closeSnackbar();
      await signOut();
      navigate("/");
      document.title = SITE_TITLE;
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return handleSignOut;
};
