import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface PatientInitialsProps {
  help?: string;
  onChange?: () => void;
}

const PatientInitials = ({ help, onChange }: PatientInitialsProps): React.JSX.Element => {
  const { register, formState, watch } = useFormContext<CaseUiData>();
  const { useRecordNumberForPatientName } = watch();

  return (
    <FormInput
      id="patientInitials"
      label="Initials"
      className="is-uppercase"
      readOnly={useRecordNumberForPatientName}
      error={formState.errors.patientInitials}
      help={help}
      {...register("patientInitials", {
        setValueAs: (value: string) => value.toUpperCase(),
        onChange,
      })}
    />
  );
};

export default PatientInitials;
