import React from "react";
import { useFormContext } from "react-hook-form";

import Fieldset from "../../components/forms/Fieldset";
import { getInitialsFromPatientName } from "../../helpers/strings";
import { setValueConfig } from "../FormWrapper";
import {
  PatientDateOfBirth,
  PatientFirstName,
  PatientIdentifier,
  PatientIdentifierAlternative,
  PatientInitials,
  PatientResearchId,
  PatientResearchStudy,
  PatientSex,
  PatientSurname,
} from "../fields/patient";
import { CaseUiData } from "../schema/CaseUiSchema";

interface PatientDetailsProps {
  disabled: boolean;
}

const PatientDetails = ({ disabled }: PatientDetailsProps): React.JSX.Element => {
  const { getValues, setValue } = useFormContext<CaseUiData>();

  const updatePatientInitials = (): void => {
    const firstName = getValues("patientFirstName");
    const surname = getValues("patientSurname");
    const initials = getInitialsFromPatientName(firstName, surname);
    setValue("patientInitials", initials, setValueConfig);
  };

  return (
    <Fieldset title="Patient details" disabled={disabled}>
      <div className="columns">
        <div className="column is-4 is-3-widescreen">
          <PatientIdentifier allowPatientIdentifierNotProvided />
        </div>
        <div className="column is-4 is-3-widescreen">
          <PatientIdentifierAlternative />
        </div>
        <div className="column is-4 is-3-widescreen">
          <PatientDateOfBirth />
        </div>
      </div>
      <PatientSex />
      <div className="columns">
        <div className="column is-5 is-4-desktop is-3-widescreen">
          <PatientFirstName onChange={updatePatientInitials} />
        </div>
        <div className="column is-5 is-4-desktop is-3-widescreen">
          <PatientSurname onChange={updatePatientInitials} />
        </div>
        <div className="column is-2">
          <PatientInitials />
        </div>
      </div>
      <div className="columns">
        <div className="column is-5 is-4-desktop is-3-widescreen">
          <PatientResearchStudy />
        </div>
        <div className="column is-5 is-4-desktop is-3-widescreen">
          <PatientResearchId />
        </div>
      </div>
    </Fieldset>
  );
};

export default PatientDetails;
