import React, { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";

import FormCheckbox from "../../../components/forms/FormCheckbox";
import { patterns } from "../../../helpers/validation";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface UseRecordNumberForPatientNameProps {
  onChange: (x: ChangeEvent<HTMLInputElement>) => void;
}

const UseRecordNumberForPatientName = ({
  onChange,
}: UseRecordNumberForPatientNameProps): React.JSX.Element => {
  const { register, watch } = useFormContext<CaseUiData>();
  const { recordNumber } = watch();

  return (
    <FormCheckbox
      id="useRecordNumberForPatientName"
      label="Use as patient name"
      disabled={!patterns.recordNumber.test(recordNumber)}
      {...register("useRecordNumberForPatientName", { onChange })}
    />
  );
};

export default UseRecordNumberForPatientName;
