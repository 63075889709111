import React from "react";

interface DialogProps {
  children: React.ReactNode;
  maxWidth?: number;
  onClose?: () => void;
}

const Dialog = ({
  children,
  maxWidth = 500,
  onClose,
}: DialogProps): React.JSX.Element => {
  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content" style={{ overflow: "visible", maxWidth }}>
        <div className="box has-background-grey-lighter">
          <div className="content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
