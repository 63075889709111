import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

interface LabelledIconProps {
  icon: IconDefinition;
  label: string;
  // Extend from https://versions.bulma.io/0.9.4/documentation/helpers/color-helpers/#text-color
  color?: "success" | "danger" | "warning-dark";
}

const LabelledIcon = ({ icon, label, color }: LabelledIconProps): React.JSX.Element => {
  return (
    <span className={classNames("cy-labelled-icon", { [`has-text-${color}`]: color })}>
      <FontAwesomeIcon icon={icon} size="sm" />
      {label}
    </span>
  );
};

export default LabelledIcon;
