import classNames from "classnames";
import { isEqual } from "lodash";
import React from "react";
import { Control, Controller, FieldError, FieldValues, Path } from "react-hook-form";
import Select, { InputProps, SingleValue, components } from "react-select";

export type FormSelectOption = { label: string; value: string };

export const FormSelectPlaceholder = "Select...";

interface FormSelectProps<T extends FieldValues> {
  id: string;
  label: string;
  control: Control<T>;
  options: FormSelectOption[];
  noMargin?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  placeholder?: string;
  help?: string;
  error?: FieldError;
  onChange?: (option: FormSelectOption | null) => void;
}

// Add 'name' attribute to the text input field
const Input = (props: InputProps<FormSelectOption>) => (
  <components.Input {...props} name={props.selectProps.name} />
);

const FormSelect = <T extends FieldValues>({
  id,
  label,
  control,
  options,
  noMargin,
  autoFocus = false,
  disabled = false,
  clearable = false,
  placeholder = FormSelectPlaceholder,
  help,
  error,
  onChange: customOnChange,
}: FormSelectProps<T>): React.JSX.Element => {
  const inputElementId = id + "Select";
  return (
    <div className={classNames("field", { "mb-5": !noMargin, disabled })}>
      {label && (
        <label htmlFor={inputElementId} className="label">
          {label}
        </label>
      )}
      <div className="control">
        <Controller
          name={id as Path<T>}
          control={control}
          render={({ field: { onChange, value, ref } }) => {
            const selected = options.find((option) => option.value === value) || null;
            const handleChange = (option: SingleValue<FormSelectOption>): void => {
              onChange(option?.value ?? "");
              if (!isEqual(option, selected)) customOnChange?.(option);
            };
            return (
              <Select
                ref={ref}
                name={id}
                isMulti={false}
                value={selected}
                inputId={inputElementId}
                options={options}
                autoFocus={autoFocus}
                isDisabled={disabled}
                isClearable={clearable}
                placeholder={placeholder}
                components={{ Input }}
                onChange={handleChange}
                menuPlacement="auto"
                classNamePrefix="react-select"
                className={classNames("react-select-container", { "has-error": error })}
              />
            );
          }}
        />
      </div>
      {help && !error && <p className="help">{help}</p>}
      {error && <p className="help has-text-danger">{error.message}</p>}
    </div>
  );
};

export default FormSelect;
