import React from "react";

interface DialogActionsProps {
  children: React.ReactNode;
}

const DialogActions = ({ children }: DialogActionsProps): React.JSX.Element => {
  return <div className="buttons">{children}</div>;
};

export default DialogActions;
