import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { CaseStatusFilterLabels } from "../../helpers/case";
import { CaseStatusFilter } from "../../schemas/ApiSchema";
import { AppDispatch } from "../../store";
import {
  SearchParameters,
  removeSearchParameter,
  selectSearchParameters,
} from "../../store/caseListSlice";
import { selectUserGroupLabel } from "../../store/metadataSlice";

interface SearchTag {
  key: keyof SearchParameters;
  label?: string;
  value: string;
}

export const searchTagLabels: Partial<Record<keyof SearchParameters, string>> = {
  labNumber: "Case number",
  recordNumber: "Kit ID",
  userGroupId: "User group",
  status: "Status",
  patientFirstName: "Patient first name",
  patientSurname: "Patient surname",
  patientIdentifier: "NHS/CHI number",
};

const SearchTags = (): React.JSX.Element | null => {
  // Redux
  const searchParameters = useSelector(selectSearchParameters);
  const userGroup = useSelector(selectUserGroupLabel(searchParameters.userGroupId));
  const dispatch = useDispatch<AppDispatch>();

  const handleDelete = (key: keyof SearchParameters): void => {
    dispatch(removeSearchParameter(key));
  };

  const getSearchTagValue = (key: keyof SearchParameters, value: string): string => {
    switch (key) {
      case "userGroupId":
        return userGroup;
      case "status":
        return CaseStatusFilterLabels[value as CaseStatusFilter];
      default:
        return value;
    }
  };

  const searchTags: SearchTag[] = Object.entries(searchParameters).map(
    ([name, value]) => {
      const key = name as keyof SearchParameters;
      return {
        key,
        label: searchTagLabels[key],
        value: getSearchTagValue(key, value),
      };
    }
  );

  if (searchTags.length === 0) return null;

  return (
    <div className="tags are-medium">
      {searchTags.map(({ key, label, value }) => (
        <span key={key} className="tag is-link is-rounded" data-testid={`${key}Tag`}>
          {label}:<span className="ml-1 has-text-weight-bold">{value}</span>
          <button type="button" className="delete ml-2" onClick={() => handleDelete(key)}>
            Remove
          </button>
        </span>
      ))}
    </div>
  );
};

export default SearchTags;
