import { noop } from "lodash";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { Answer, CaseSchemaOptions } from "../../schema/FormAnswers";

const NumberOfFailedAttempts = (): React.JSX.Element => {
  const { register, getValues, setValue, formState } = useFormContext<CaseUiData>();

  /**
   * This safety net throws away a "Yes" answer rather than mapping it
   * to "1" when switching from a form with "Yes/No" answers to a legacy
   * form with "None/1/2" answers.
   *
   * Unfortunately it also throws away a "1" answer when switching between
   * legacy forms, but this is unavoidable as the newly-selected form has
   * no knowledge of the previously-selected form.
   */
  useEffect(() => {
    if (getValues("numberOfFailedAttempts") === Answer.ONE) {
      setValue("numberOfFailedAttempts", "");
    }
  }, [getValues, setValue]);

  return (
    <RadioGroup
      id="numberOfFailedAttempts"
      label="Number of failed attempts"
      // Legacy forms offered option to select "2" failed attempts
      options={[...CaseSchemaOptions.numberOfFailedAttempts, Answer.TWO]}
      error={formState.errors.numberOfFailedAttempts}
      // Forget about previously-registered onChange events
      {...register("numberOfFailedAttempts", { onChange: noop })}
    />
  );
};

export default NumberOfFailedAttempts;
