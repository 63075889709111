import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const TEST_ID_FORM_NO_ISSUES_BUTTON = "FormNoIssuesButton";

interface NoIssuesButtonProps {
  formLocked: boolean;
  handleClick: () => void;
}

// Shortcut button for procedures with no issues to save clicks
const NoIssuesButton = ({
  formLocked,
  handleClick,
}: NoIssuesButtonProps): React.JSX.Element | null => {
  if (formLocked) return null;
  return (
    <button
      type="button"
      disabled={formLocked}
      className="button is-small is-link is-light ml-3"
      data-testid={TEST_ID_FORM_NO_ISSUES_BUTTON}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faBolt} className="mr-2" />
      No issues
    </button>
  );
};

export default NoIssuesButton;
