import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { TEST_ID_USER_LIST_TABLE } from "../../routes/UserList";
import { PathologistUser } from "../../schemas/PathologistSchema";
import PathologistRow from "./PathologistRow";

interface PathologistTableProps {
  users: PathologistUser[];
}

const PathologistTable = ({ users }: PathologistTableProps): React.JSX.Element => {
  return (
    <table className="table is-fullwidth" data-testid={TEST_ID_USER_LIST_TABLE}>
      <thead>
        <tr>
          <th>
            Name <FontAwesomeIcon icon={faCaretUp} className="ml-1" />
          </th>
          <th>LIMS consultant</th>
          <th>Known as</th>
          <th>Email</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <PathologistRow user={user} key={user.userId} />
        ))}
      </tbody>
    </table>
  );
};

export default PathologistTable;
