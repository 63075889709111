import { faCircle, faPlay } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import React, { MouseEvent } from "react";

import { Special, SpecialState } from "../../schemas/SpecialRequestSchema";
import LabelledIcon from "../atoms/LabelledIcon";
import MissingFieldTag from "../atoms/MissingFieldTag";

export const TEST_ID_UPDATE_REQUEST_BUTTON = "UpdateRequestButton";

interface SpecialRequestRowProps {
  special: Special;
  openDialog: (e: MouseEvent, id: string) => void;
}

const SpecialRequestRow = ({
  special: {
    id: requestId,
    labNumber,
    recordNumber,
    dateRequested,
    requestedByName,
    block,
    details,
    state,
  },
  openDialog,
}: SpecialRequestRowProps): React.JSX.Element => {
  const requestDate: string = format(dateRequested, "d MMM yyyy, h.mmaaa"); // eg. 19 Jun 2024, 1.52pm

  return (
    <tr>
      <td>
        <div className="cy-user-row__name">{labNumber}</div>
        <div className="cy-user-row__email">{recordNumber}</div>
      </td>
      <td>
        <div className="cy-user-row__name">{requestDate}</div>
        <div className="cy-user-row__email">
          {requestedByName || <MissingFieldTag message="Name unknown" />}
        </div>
      </td>
      <td>{block}</td>
      <td>{details}</td>
      <td className="cy-user-row__status">
        <LabelledIcon
          icon={faCircle}
          label={state}
          color={state === SpecialState.ORDERED ? "warning-dark" : "success"}
        />
      </td>
      <td>
        <a
          href=""
          role="button"
          className="is-block mb-1"
          data-testid={TEST_ID_UPDATE_REQUEST_BUTTON}
          onClick={(e) => openDialog(e, requestId)}
        >
          <LabelledIcon icon={faPlay} label="Update" />
        </a>
      </td>
    </tr>
  );
};

export default SpecialRequestRow;
