import React from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { Answer, CaseSchemaOptions } from "../../schema/FormAnswers";

const TakesAnticoagulants = (): React.JSX.Element => {
  const { register, formState, watch } = useFormContext<CaseUiData>();
  const { isBloodOnSponge } = watch();

  return (
    <RadioGroup
      indented
      id="takesAnticoagulants"
      label="If Yes, does the patient take anticoagulants?"
      disabled={isBloodOnSponge !== Answer.YES}
      options={CaseSchemaOptions.yesNoUnanswered}
      error={formState.errors.takesAnticoagulants}
      {...register("takesAnticoagulants")}
    />
  );
};

export default TakesAnticoagulants;
