import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CaseUiData } from "../../schema/CaseUiSchema";

const PractitionerName = (): React.JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();
  return (
    <FormInput
      addMargin
      id="practitionerName"
      label="Practitioner name"
      help="Healthcare professional who performed the procedure"
      error={formState.errors.practitionerName}
      {...register("practitionerName")}
    />
  );
};

export default PractitionerName;
