import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { FieldError } from "react-hook-form";

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  type?: "text" | "number";
  readOnly?: boolean;
  addMargin?: boolean;
  className?: string;
  icon?: IconDefinition;
  help?: string;
  error?: FieldError;
}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      id,
      label,
      type = "text",
      readOnly,
      addMargin,
      className,
      icon,
      help,
      error,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={classNames("field", { "mb-5": addMargin, disabled: props.disabled })}
      >
        <label htmlFor={id} className="label">
          {label}
        </label>
        <div className={classNames("control", { "has-icons-left": icon })}>
          <input
            id={id}
            ref={ref}
            type={type}
            readOnly={readOnly}
            aria-invalid={error ? "true" : "false"}
            className={classNames(
              "input",
              { "is-static": readOnly, "is-danger": error },
              className
            )}
            {...props}
          />
          {icon && (
            <span className="icon is-left">
              <FontAwesomeIcon icon={icon} />
            </span>
          )}
        </div>
        {help && !error && <p className="help">{help}</p>}
        {error && (
          <p className="help has-text-danger" id={`${id}Error`}>
            {error.message}
          </p>
        )}
      </div>
    );
  }
);

FormInput.displayName = "FormInput";

export default FormInput;
