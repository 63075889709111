import { IconDefinition, faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { MouseEvent } from "react";

import { getCaseStateDescription } from "../../helpers/case";
import { CaseDialogType } from "../../routes/CaseEdit";
import { CaseInfo } from "../../schemas/ApiSchema";

export const TEST_ID_CASE_STATE_BANNER = "CaseStateBanner";
export const TEST_ID_CASE_STATE_BANNER_ICON = "CaseStateBannerIcon";
export const TEST_ID_CASE_STATE_BANNER_TITLE = "CaseStateBannerTitle";
export const TEST_ID_CASE_STATE_BANNER_SUBTITLE = "CaseStateBannerSubtitle";
export const TEST_ID_CASE_STATE_BANNER_SAVE_CHANGES_BUTTON =
  "CaseStateBannerSaveChangesButton";
export const TEST_ID_CASE_STATE_BANNER_LOCK_UNLOCK_BUTTON =
  "CaseStateBannerLockUnlockButton";

interface CaseStateBannerProps {
  caseInfo: CaseInfo;
  pathologistName?: string;
  submitCaseForm: () => void;
  openCaseDialog: (d: CaseDialogType, e?: MouseEvent) => void;
}

const CaseStateBanner = ({
  caseInfo: {
    caseState,
    permissions: { canEditCase, operations, authorisationBlockers },
  },
  pathologistName,
  submitCaseForm,
  openCaseDialog,
}: CaseStateBannerProps): React.JSX.Element => {
  const bannerColor: string = canEditCase ? "is-link" : "is-warning";
  const bannerIcon: IconDefinition = canEditCase ? faUnlock : faLock;
  const bannerTitle: string = canEditCase ? "Open" : "Locked";
  const bannerSubtitle: string = getCaseStateDescription(
    caseState,
    authorisationBlockers,
    pathologistName
  );

  return (
    <div
      className={classNames("message is-light", bannerColor)}
      data-testid={TEST_ID_CASE_STATE_BANNER}
    >
      <div className="message-body">
        <div className="columns is-mobile is-vcentered">
          <div className="column is-narrow">
            <FontAwesomeIcon
              icon={bannerIcon}
              size="xl"
              data-testid={TEST_ID_CASE_STATE_BANNER_ICON}
            />
          </div>
          <div className="column">
            <p
              className="has-text-weight-bold"
              data-testid={TEST_ID_CASE_STATE_BANNER_TITLE}
            >
              {bannerTitle}
            </p>
            {!!bannerSubtitle && (
              <p data-testid={TEST_ID_CASE_STATE_BANNER_SUBTITLE}>{bannerSubtitle}</p>
            )}
          </div>
          <div className="column is-narrow">
            <div className="buttons">
              {canEditCase && (
                <button
                  type="button"
                  className={classNames("button", bannerColor)}
                  onClick={submitCaseForm}
                  data-testid={TEST_ID_CASE_STATE_BANNER_SAVE_CHANGES_BUTTON}
                >
                  Save changes
                </button>
              )}
              {!isEmpty(operations) && (
                <button
                  type="button"
                  className={classNames("button", bannerColor, {
                    // Becomes secondary action when 'Save changes' button is visible
                    "is-outlined": canEditCase,
                  })}
                  onClick={(e) => openCaseDialog(CaseDialogType.STATE, e)}
                  data-testid={TEST_ID_CASE_STATE_BANNER_LOCK_UNLOCK_BUTTON}
                >
                  {operations[0].operation} case
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStateBanner;
