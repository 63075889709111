import React, { CSSProperties } from "react";

interface AuthenticatorHeadingProps {
  children: React.ReactNode;
  style?: CSSProperties;
}

const AuthenticatorHeading = ({
  children,
  style,
}: AuthenticatorHeadingProps): React.JSX.Element => {
  return (
    <h1 className="amplify-heading amplify-heading--4 mb-1" style={style}>
      {children}
    </h1>
  );
};

export default AuthenticatorHeading;
