import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import useFieldModifiers from "../../hooks/useFieldModifiers";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { Answer, CaseSchemaOptions } from "../../schema/FormAnswers";

const NumberOfFailedAttempts = (): React.JSX.Element => {
  const { register, formState } = useFormContext<CaseUiData>();
  const { updateFailedAttemptFields } = useFieldModifiers();

  useEffect(() => {
    updateFailedAttemptFields();
  }, [updateFailedAttemptFields]);

  // Maps options "None" to "No" and "1" to "Yes"
  const numberOfFailedAttemptsLabels: Record<string, Answer> = {
    [CaseSchemaOptions.numberOfFailedAttempts[0]]: Answer.NO,
    [CaseSchemaOptions.numberOfFailedAttempts[1]]: Answer.YES,
  };

  const getNumberOfFailedAttemptsLabel = (option: string) => {
    return numberOfFailedAttemptsLabels[option];
  };

  return (
    <RadioGroup
      id="numberOfFailedAttempts"
      label="Was there a failed attempt?"
      // Reverse the order of "None" and "1" in the case schema to match "Yes" and "No"
      options={[...CaseSchemaOptions.numberOfFailedAttempts].reverse()}
      getOptionLabel={getNumberOfFailedAttemptsLabel}
      error={formState.errors.numberOfFailedAttempts}
      {...register("numberOfFailedAttempts", { onChange: updateFailedAttemptFields })}
    />
  );
};

export default NumberOfFailedAttempts;
