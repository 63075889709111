import React from "react";
import { useFormContext } from "react-hook-form";

import Fieldset from "../../components/forms/Fieldset";
import FormSelect from "../../components/forms/FormSelect";
import { FormMode } from "../FormWrapper";
import { CaseUiData } from "../schema/CaseUiSchema";
import { RequestFormOptions } from "../schema/RequestForms";

interface RequestFormVersionProps {
  disabled: boolean;
  formMode: FormMode;
}

const RequestFormVersion = ({
  disabled,
  formMode,
}: RequestFormVersionProps): React.JSX.Element => {
  const { control, formState, setValue, clearErrors } = useFormContext<CaseUiData>();

  /**
   * There is a risk that users will select the wrong specimen type when
   * they have started filling out the form. The team decided it would be
   * less risky to clear the specimen type field on changing request form
   * type. See UAT for release 1.56.0 for more information.
   *
   * It was also agreed to clear any field validation errors when changing
   * request form type to prevent stale errors from persisting in the UI.
   */
  const handleChange = (): void => {
    setValue("specimen", "");
    clearErrors();
  };

  return (
    <Fieldset title="Request form" disabled={disabled}>
      <div className="columns">
        <div className="column is-half-desktop">
          <FormSelect
            noMargin
            autoFocus={formMode === "create"}
            id="requestFormType"
            label="TRF version"
            disabled={disabled}
            control={control}
            options={RequestFormOptions}
            error={formState.errors.requestFormType}
            onChange={handleChange}
          />
        </div>
      </div>
    </Fieldset>
  );
};

export default RequestFormVersion;
