import React from "react";
import { useFormContext } from "react-hook-form";

import FormSelect, { FormSelectOption } from "../../../components/forms/FormSelect";
import { CaseUiData } from "../../schema/CaseUiSchema";

interface LimsCaseOriginProps {
  caseOrigins: FormSelectOption[];
}

const LimsCaseOrigin = ({ caseOrigins }: LimsCaseOriginProps): React.JSX.Element => {
  const { control } = useFormContext<CaseUiData>();

  return (
    <FormSelect
      id="caseOrigin"
      label="LIMS case origin"
      disabled
      control={control}
      options={caseOrigins}
      placeholder=""
      help="⚠ Controlled by user group"
    />
  );
};

export default LimsCaseOrigin;
