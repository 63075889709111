import classNames from "classnames";
import React from "react";

interface BoxProps {
  className?: string;
  title?: string;
  addon?: React.ReactNode;
  children: React.ReactNode;
}

const Box = ({ className, title, addon, children }: BoxProps): React.JSX.Element => {
  return (
    <div className={classNames("box p-5", className)}>
      {title && (
        <h3 className="title is-4">
          {title}
          {addon}
        </h3>
      )}
      {children}
    </div>
  );
};

export default Box;
