import React from "react";
import { useFormContext } from "react-hook-form";

import FormInput from "../../../components/forms/FormInput";
import { CaseUiData } from "../../schema/CaseUiSchema";

const PractitionerLocation = (): React.JSX.Element => {
  const { register, formState, watch } = useFormContext<CaseUiData>();
  const { useClinicianForProcedureSite } = watch();

  return (
    <FormInput
      addMargin
      id="practitionerLocation"
      label="Practitioner’s hospital/site/practice"
      readOnly={useClinicianForProcedureSite}
      error={formState.errors.practitionerLocation}
      {...register("practitionerLocation")}
    />
  );
};

export default PractitionerLocation;
