import classNames from "classnames";
import React from "react";
import { useFormContext } from "react-hook-form";

import Fieldset from "../../components/forms/Fieldset";
import { FormMode, setValueConfig } from "../FormWrapper";
import {
  ConfidentSpongeReachedStomach,
  DeviceIdForFailedAttempt,
  IsBloodOnSponge,
  NumberOfFailedAttempts,
  PractitionerLocation,
  PractitionerName,
  ProcedureDate,
  ReasonsForFailedAttempt,
} from "../fields/procedure";
import NoIssuesButton from "../fields/procedure/NoIssuesButton";
import useFieldModifiers from "../hooks/useFieldModifiers";
import { CaseUiData } from "../schema/CaseUiSchema";
import { Answer } from "../schema/FormAnswers";

interface ProcedureDetailsProps {
  formMode: FormMode;
  formLocked: boolean;
}

const ProcedureDetails = ({
  formMode,
  formLocked,
}: ProcedureDetailsProps): React.JSX.Element => {
  const { setValue, watch } = useFormContext<CaseUiData>();
  const { updateFailedAttemptFields } = useFieldModifiers();

  const { numberOfFailedAttempts } = watch();
  const noFailedAttempts: boolean = numberOfFailedAttempts !== Answer.ONE;

  const handleClick = (): void => {
    setValue("isBloodOnSponge", Answer.NO, setValueConfig);
    setValue("confidentSpongeReachedStomach", Answer.YES, setValueConfig);
    setValue("numberOfFailedAttempts", Answer.NONE, setValueConfig);
    updateFailedAttemptFields();
  };

  return (
    <Fieldset
      title="Procedure details"
      disabled={formLocked}
      addon={<NoIssuesButton formLocked={formLocked} handleClick={handleClick} />}
    >
      <div className="columns">
        <div className="column is-half">
          <ConfidentSpongeReachedStomach />
          <IsBloodOnSponge />
          <NumberOfFailedAttempts />
          <div className={classNames("indented", { disabled: noFailedAttempts })}>
            <DeviceIdForFailedAttempt disabled={noFailedAttempts} />
            <ReasonsForFailedAttempt disabled={noFailedAttempts} />
          </div>
        </div>
        <div className="column is-half">
          <PractitionerName />
          <PractitionerLocation />
          <div className="columns is-mobile">
            <div className="column is-half">
              <ProcedureDate formMode={formMode} />
            </div>
          </div>
        </div>
      </div>
    </Fieldset>
  );
};

export default ProcedureDetails;
